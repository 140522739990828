@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

:root {
  --toastify-color-success: #04844B;
}
.ant-progress-inner {
  border-radius: 0 !important;
}

.ant-progress-bg {
  border-radius: 0 !important;
}

.ant-progress-success-bg {
  border-radius: 0 !important;
}

.color-blue{
  color: #008AC8;
}

.ant-form-item-label > label {
  font-weight: normal !important;
  font-size: 12px;
}

.ant-form-item .ant-form-item-label {
  padding: 0 !important;
}

.ant-input {
  font-size: 18px !important;
  padding: 5px 10px !important;
  border: 1px solid #f0eeee !important;
}

.ant-progress-line {
  margin-top: 5px;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 150ms ease-in;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.dot {
  width: 15px;
  height: 15px;
  border: 2px solid #008AC8;
  border-radius: 50%;
  background: #008AC8;
  float: left;
  margin: 0 5px;
  transform: scale(0);
  -webkit-animation: fx 1500ms ease infinite 0ms;
          animation: fx 1500ms ease infinite 0ms;
}
.dot:nth-child(2) {
  -webkit-animation: fx 1500ms ease infinite 300ms;
          animation: fx 1500ms ease infinite 300ms;
}
.dot:nth-child(3) {
  -webkit-animation: fx 1500ms ease infinite 600ms;
          animation: fx 1500ms ease infinite 600ms;
}
@-webkit-keyframes fx {
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fx {
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* The emerging W3C standard
   that is currently Firefox-only */
   * {
   scrollbar-width: thin;
   scrollbar-color: graytext white;
 }
 
 /* Works on Chrome/Edge/Safari */
 *::-webkit-scrollbar {
   width: 6px;
   height: 4px;
 }
 *::-webkit-scrollbar-track {
   background: white;
 }
 *::-webkit-scrollbar-thumb {
   background-color: gray;
   border-radius: 20px;
   border: 1px solid white;
 }

 /* phone animation */

 :root {
   --phone-color: #67CE67;
 }

 .Phone-in-desk {
  position: relative;
  top: -70px;
  left: 380px;
  display: block;
  margin: 0;
  width: 40px;
  height: 40px;
  font-size: 25vmin;
  background-color: #67CE67;
  border-radius: 0.5em;
  box-shadow: 0 0 0 0em rgba(52, 152, 219, 0), 0em 0.05em 0.1em rgba(0, 0, 0, 0.2);
  transform: translate3d(0, 0, 0) scale(1);
}

 .Phone-in {
  position: relative;
  top: -70px;
  left: 230px;
  display: block;
  margin: 0;
  width: 40px;
  height: 40px;
  font-size: 25vmin;
  background-color: #67CE67;
  border-radius: 0.5em;
  box-shadow: 0 0 0 0em rgba(52, 152, 219, 0), 0em 0.05em 0.1em rgba(0, 0, 0, 0.2);
  transform: translate3d(0, 0, 0) scale(1);
}

 .Phone {
  position: relative;
  top: -70px;
  left: 145px;
  display: block;
  margin: 0;
  width: 40px;
  height: 40px;
  font-size: 25vmin;
  background-color: #67CE67;
  border-radius: 0.5em;
  box-shadow: 0 0 0 0em rgba(52, 152, 219, 0), 0em 0.05em 0.1em rgba(0, 0, 0, 0.2);
  transform: translate3d(0, 0, 0) scale(1);
}

.Phone::before,
.Phone::after,
.Phone-in::before,
.Phone-in::after,
.Phone-in-desk::before,
.Phone-in-desk::after {
  position: absolute;
  content: "";
}

.Phone::before, .Phone-in::before, .Phone-in-desk::before {
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 100%;
  opacity: 1;
  transform: translate3d(0, 0, 0) scale(0);
}

.Phone::after, .Phone-in::after, .Phone-in-desk::after {
  top: 5px;
  left: 5px;
  width: 30px;
  height: 30px;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTYuNiAxMC44YzEuNCAyLjggMy44IDUuMSA2LjYgNi42bDIuMi0yLjJjLjMtLjMuNy0uNCAxLS4yIDEuMS40IDIuMy42IDMuNi42LjUgMCAxIC40IDEgMVYyMGMwIC41LS41IDEtMSAxLTkuNCAwLTE3LTcuNi0xNy0xNyAwLS42LjQtMSAxLTFoMy41Yy41IDAgMSAuNCAxIDEgMCAxLjIuMiAyLjUuNiAzLjYuMS40IDAgLjctLjIgMWwtMi4zIDIuMnoiIGZpbGw9IiNmZmZmZmYiLz48L3N2Zz4=);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translate3d(0, 0, 0);
}

.Phone.is-animating, .Phone-in.is-animating, .Phone-in-desk.is-animating {
  -webkit-animation: phone-outer 3000ms infinite;
          animation: phone-outer 3000ms infinite;
}
.Phone.is-animating::before, .Phone-in.is-animating::before, .Phone-in-desk.is-animating::before {
  -webkit-animation: phone-inner 3000ms infinite;
          animation: phone-inner 3000ms infinite;
}
.Phone.is-animating::after, .Phone-in.is-animating::after, .Phone-in-desk.is-animating::after {
  -webkit-animation: phone-icon 3000ms infinite;
          animation: phone-icon 3000ms infinite;
}

@-webkit-keyframes phone-outer {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
    box-shadow: 0 0 0 0em rgba(52, 152, 219, 0), 0em 0.05em 0.1em rgba(0, 0, 0, 0.2);
  }
  33.3333% {
    transform: translate3d(0, 0, 0) scale(1.1);
    box-shadow: 0 0 0 0em rgba(52, 152, 219, 0.1), 0em 0.05em 0.1em rgba(0, 0, 0, 0.5);
  }
  66.6666% {
    transform: translate3d(0, 0, 0) scale(1);
    box-shadow: 0 0 0 0.5em rgba(52, 152, 219, 0), 0em 0.05em 0.1em rgba(0, 0, 0, 0.2);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
    box-shadow: 0 0 0 0em rgba(52, 152, 219, 0), 0em 0.05em 0.1em rgba(0, 0, 0, 0.2);
  }
}

@keyframes phone-outer {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
    box-shadow: 0 0 0 0em rgba(52, 152, 219, 0), 0em 0.05em 0.1em rgba(0, 0, 0, 0.2);
  }
  33.3333% {
    transform: translate3d(0, 0, 0) scale(1.1);
    box-shadow: 0 0 0 0em rgba(52, 152, 219, 0.1), 0em 0.05em 0.1em rgba(0, 0, 0, 0.5);
  }
  66.6666% {
    transform: translate3d(0, 0, 0) scale(1);
    box-shadow: 0 0 0 0.5em rgba(52, 152, 219, 0), 0em 0.05em 0.1em rgba(0, 0, 0, 0.2);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
    box-shadow: 0 0 0 0em rgba(52, 152, 219, 0), 0em 0.05em 0.1em rgba(0, 0, 0, 0.2);
  }
}
@-webkit-keyframes phone-inner {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(0);
  }
  33.3333% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(0.9);
  }
  66.6666% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(0);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(0);
  }
}
@keyframes phone-inner {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(0);
  }
  33.3333% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(0.9);
  }
  66.6666% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(0);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(0);
  }
}
@-webkit-keyframes phone-icon {
  0% {
    transform: translate3d(0em, 0, 0);
  }
}
@keyframes phone-icon {
  0% {
    transform: translate3d(0em, 0, 0);
  }
}

.phone-icon {
  width: 20px;
  height: 20px;
  margin: 0px 5px 2px 0px;
}